export const API_URL = 'https://api.cultofneon.art'; // PRODUCTION
// export const API_URL = 'http://localhost:8080';  // LOCAL

export const TWITTER_URL = 'https://twitter.com/Lux_capacitor'
export const DISCORD_URL = 'https://discord.gg/t2JxgHcx6h'
export const INSTAGRAM_URL = 'https://instagram.com/lux_capacitor'
export const FOUNDATION_URL = 'https://foundation.app/@cult_of_neon'
export const FOUNDATION_COLLECTION_URL = 'https://foundation.app/collection/cult-of-neon'
export const OPENSEA_URL = 'https://opensea.io/collection/cult-of-neon'
export const COLLECTION_URL = 'https://opensea.io/collection/cult-of-neon'
export const DIRECT_ITEM_URL = 'https://opensea.io/assets/ethereum/0x54142a074a319a0012ca75de741dc3fe68f9bb90/'


// NOT USED
export const DEPLOY_ENV = process.env.GATSBY_DEPLOY_ENV;
export const INFURA_ID = process.env.GATSBY_INFURA_ID;
export const CONTRACT_ADDRESS = process.env.GATSBY_CONTRACT_ADDRESS || (DEPLOY_ENV === 'mainnet' ? '' : '');

export const chainsConfig = [
  {
    name: 'localhost',
      chain: 'ETH',
      network: 'mainnet',
      chain_id: 13317,
      network_id: 13317,
      rpc_url: 'http://localhost:8545',
  },
  {
    name: 'Ethereum.Mainnet',
    short_name: 'eth',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 1,
    network_id: 1,
    rpc_url: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Ropsten',
    short_name: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chain_id: 3,
    network_id: 3,
    rpc_url: 'https://ropsten.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Rinkeby',
    short_name: 'rin',
    chain: 'ETH',
    network: 'rinkeby',
    chain_id: 4,
    network_id: 4,
    rpc_url: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
  {
    name: 'Ethereum Görli',
    short_name: 'gor',
    chain: 'ETH',
    network: 'goerli',
    chain_id: 5,
    network_id: 5,
    rpc_url: 'https://goerli.infura.io/v3/%API_KEY%',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: '',
    },
  },
];  
