import React, { useState } from "react";
import fetch from 'isomorphic-unfetch';
import { ethers } from "ethers";
import web3 from 'web3';
import { API_URL } from "../constants";
const apiUrl = process.env.API_URL || API_URL;

const LuxWeb3Context = React.createContext();
const LuxWeb3Provider = ({ children }) => {

  // STATE : PAGE LOCATION
  const [location, setPageLocation] = useState('home');
  const [enlightenmentMode, setEnlightenmentMode] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  // STATE : WALLET
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [walletEns, setWalletEns] = useState(null);
  const [walletMessage, setWalletMessage] = useState(null);
  const [walletSignature, setWalletSignature] = useState(null);
  const [web3Error, setWalletConnectError] = useState(null);

  // STATE : NFTs / PROFILE 
  const [profileReady, setProfileReady] = useState(false);
  const [profileError, setProfileError] = useState(null);
  const [memberCount, setMemberCount] = useState(null);
  const [memberLevel, setMemberLevel] = useState(null);
  const [NFTs, setNFTs] = useState([]);
  const [NftInForge, setNftToForge] = useState(null);
  const [availableNFTs, setAvailableNFTs] = useState([]);

  // STATE : FORM SUBMISSION DATA
  const [formData, setFormData] = useState({});
  
  // SHOPIFY : INTEGRATION TO SHOPIFY MODULES
  const [shopifyProducts, setProducts] = useState([])                 // FETCH PRODUCTS
  const [isShopifyFetching, setShopifyIsFetching] = useState(false);  // LOADING DETECTION


/*
 *             SHOPIFY METHODS
 * ---------------------------------------------- */
// SHOPIFY : Get Store Products
const getShopifyProducts = async () => {
  try {
    const rawRes = await fetch(`${apiUrl}/store/products`, { method: 'get', headers: {'Access-Control-Allow-Origin': 'http://localhost:9002' }})
    const response = await rawRes.json();
    console.log( `PRODUCTS FETCHED : ${response.products}`);
    setProducts(response.products);
  }
  catch(e) {
    const theError = e.message && typeof e.message == 'string' ? e.message : 'Try again, thy worthiness could not be determined. (Connection Error)';
    let cleanError = theError;
    console.log(cleanError);
  }
}


/*
 *             UI UTIL METHODS
 * ---------------------------------------------- */
  // UITLS : CONNECT WALLET (Sign Wallet)
  const connectWallet = async () => {
    try {

      // CHECK : Metamask Present?
      if ( !window.ethereum ) {
        throw new Error('No crypto wallet found. Please install Metamask.');
      }

      // CONNECT
      const info = await getIdentity();
      const message = `.*//-- CULT OF NEON --//*.\n\nWelcome to the Cult of Neon\n\nConnect your wallet to enter our domain & summon your NFT to the physical world as 1/1 Neon art.\n\nNonce: ${info.id}`;
      setWalletMessage(message);
      setWalletConnectError(null);

        // A Web3Provider wraps a standard Web3 provider, which MetaMask injects as window.ethereum
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any")

        // MetaMask requires requesting permission to connect user accounts
      await provider.send("eth_requestAccounts", []);

        // CHECK NETWORK
      const network = await provider.getNetwork();
      const isTestnet = (network.name === 'rinkeby' || network.name === 'goerli');
      const isMainnet = network.name === 'homestead'
      if ( !!network && ( !isTestnet && !isMainnet ) ) {
        const err = new Error('Please switch wallet network to Ethereum Mainnet');
        err.code = 9001;
        throw err;
      }

        // The MetaMask plugin also allows signing transactions to send ether and pay to change state
        // within the blockchain. For this, you need the account signer...
      const signer = provider.getSigner()
      const signature = await signer.signMessage(message);
      const address = await signer.getAddress();

      // SET : Wallet Addresses + Metadata
      setWalletSignature(signature);
      setWalletConnected(true);
      setWalletAddress(address);

      // SECURITY : Force reload page when network changes - the "any" network will allow spontaneous network changes
      provider.on("network", (newNetwork, oldNetwork) => {
          // When a Provider makes its initial connection, it emits a "network"
          // event with a null oldNetwork along with the newNetwork. So, if the
          // oldNetwork exists, it represents a changing network
          if (oldNetwork) {
              window.location.reload();
          }
      });

      // ENS : Check if they have an ENS name
      const ens = await provider.lookupAddress(address);
      if ( !!ens ) {
        setWalletEns(ens);
      }

      // CULTIST GET NFT INFO - Trigger 
      await getCultist( address, message, signature );
    }

    catch(e) {
      const theError = e.message && typeof e.message == 'string' ? e.message : 'Try again, thy worthiness could not be determined. (Connection Error)';
      let cleanError = theError;

      // ERROR SANITIZER:
      switch(true) {
        case 
        !!cleanError && cleanError.indexOf( "Request of type 'wallet_requestPermissions' already" ) !== -1 ||
        !!cleanError && cleanError.indexOf( "lready processing eth_requestAccounts" ) !== -1:
          cleanError = 'Connection already requested, check your wallet.'
          break;
        
        // USER REJECTION
        case e.code === 4001:
            cleanError = "The connection rites were rejected"
            break;

        // WRONG NETWORK
        case e.code === 9001:
            break;
        
        default:
            cleanError = "Open this site in Metamask Mobile or in a Browser with Metamask to connect your wallet & claim neon. Click = to learn more."
            break;
      }

      setWalletConnectError(cleanError);
    }
  }


  // UTILS : VERIFY CULTIST NFTs (Check NFTs)
  const getCultist = async ( address, message, signature ) => {
    try {

      // FETCH : Get NFTs + Member Level based on NFT count
      const profile = await getNFTs( address, message, signature );

      // ERROR HANDLING - in case response resturns but is oddly shaped
      if ( !profile || !profile.ownedNfts ) {
        throw new Error( 'Unable to lookup your wallet NFTs, refresh and try again please.' );
      }

      // SET MEMBER LEVEL : 
      if ( !!profile.memberLevel ) {
        setMemberLevel(profile.memberLevel || 'Initiate' );
      }
      
      // SET NFTs
      if ( profile.totalCount > 0 ) {
        setNFTs(profile.ownedNfts);
      }

      // READY - Set profile is ready
      setMemberCount(profile.totalCount);
      setProfileReady(true);
      const relocateTo = profile.totalCount < 1 ? 'initiate' : 'reliquary';
      setPageLocation( relocateTo );
    }
    catch (e) {
      const theError = e.message && typeof e.message == 'string' ? e.message : 'Unable to locate NFTs, if this is incorrect check your connected wallet address & try again. If you need any help, DM @lux_capacitor on Instagram/Twitter';
      let cleanError = theError;
      setProfileError(cleanError);
    }
  }
 

  // UTILS : SET LOCATION CLICK
  const setLocationForge = async () => {
    setPageLocation( 'forge' );
  }
  const setLocationHome = async () => {
    setPageLocation( 'home' );
  }
  const setLocationShop = async () => {
    setPageLocation( 'shop' );
  }
  const setLocationInfo = async () => {
    setPageLocation( 'info' );
  }
  const setLocationReliquary = async () => {
    setPageLocation( 'reliquary' );
  }
  const setLocationInitiate = async () => {
    setPageLocation( 'initiate' );
  }
  const setLocationLegal = async () => {
    setPageLocation( 'legal' );
  }
  const setLocationContact = async () => {
    setPageLocation( 'contact' );
  }


  // UTILS : SET INPUT FORM VALUES
  const submitForgeRequest = async (formData) => {
    try {
      setFormData(formData); // Store it to restore later if they leave or do another one 
      const forgeResults = await postForgeRequest(formData);

      // UPDATE NFT AS FORGED
      if ( !!forgeResults && !!forgeResults.status && forgeResults.status === 'success' ) {

        // UPDATE - All NFTs in array so all are aware as well 
        const updatedNFTs = NFTs.map( nft => { 
          if ( nft.id.tokenId === NftInForge.id.tokenId ) {
            nft.isForged = false;
            nft.isInProgress = true;
          }
          return nft;
        })
        setNFTs( updatedNFTs );
      }

      // RETURN STATUS
      return forgeResults;
    }
    catch (err) {
      return {
        status: 400,
        message: 'Apologies, please submit again something went wrong.'
      }
    }
  }




/*
 *           API FETCH METHODS
 * ---------------------------------------------- */
  // FETCH : GET - IDENTITY NONCE
  const getIdentity = async () => {
    return fetch(`${apiUrl}/nft/identify`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then( r => {
      return r.json();
    })
  }


  // FETCH : POST - Get Cult of Neon NFTs in Wallet via signature, address,  message
  const getNFTs = async (address, message, signature) => {
    return fetch(`${apiUrl}/nft/verify-cultist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address,
        message,
        signature
      })
    }).then( r => {
      return r.json();
    })
  }


  // FETCH : POST - Get all Available NFTs In Collection
  const getAvailableNFTs = async () => {
    return fetch(`${apiUrl}/nft/available-nfts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then( r => {
      return r.json();
    }).then( availNfts => {
      console.log( `SET : available nfts gathered \n ${availNfts}`)
      setAvailableNFTs(availNfts);
    }).catch(err => {
      console.log( `ERROR : ${err}` )
    });
  }


  // FETCH : POST - Submit the forge request form
  const postForgeRequest = async (formData) => {
    const nft = NftInForge;
    const nftId = !!nft && !!nft.id && !!nft.id.tokenId ? web3.utils.toNumber( nft.id.tokenId ) : null;
    nft.cult_id = nftId;

    return fetch(`${apiUrl}/nft/forge-neon`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address: walletAddress,
        formData,
        message: walletMessage,
        NFT: nft,
        signature: walletSignature
      })
    }).then( r => {
      return r.json();
    })
  }

  return (
    <LuxWeb3Context.Provider
      value={{
        connectWallet,
        getAvailableNFTs,
        getCultist,
        getShopifyProducts,
        setEnlightenmentMode,
        setLocationForge,
        setLocationHome,
        setLocationInitiate,
        setLocationInfo,
        setLocationLegal,
        setLocationReliquary,
        setLocationShop,
        setLocationContact,
        setMenuOpen,
        setNftToForge,
        setWalletConnectError,
        submitForgeRequest,

        // SHOPIFY CONTENT
        shopifyProducts,
        isShopifyFetching,

        // STATE + WALLET + PROFILE
        availableNFTs,
        enlightenmentMode,
        isMenuOpen,
        isWalletConnected,
        location,
        memberCount,
        memberLevel,
        NFTs,
        NftInForge,
        profileError,
        profileReady,
        walletAddress,
        walletEns,
        web3Error,

        // FORM FLOW
        formData
      }}
    >
      {children}
    </LuxWeb3Context.Provider>
  );
};

export default LuxWeb3Context;
export { LuxWeb3Provider };
