import { rgba } from "polished";
import { breakpoints } from "./breakpoints";
import { randomNumMinMax } from "./helperFn";


// UTILS : PALETTE SETUP
const randomCultPalette = () => {
  const selections = [];
  let colors = [
    '#FF01E9', // pink
    '#950AFF', // purple
    '#13FFED'  // blue
  ]

  for ( var i = 0; selections.length < 3; i++) {
    const index = randomNumMinMax( 0, colors.length - 1);
    const selected = colors[index];
    selections.push( selected );
    colors = colors.filter( color => color !== selected );
  }
  return selections;
};
const cultPalette = randomCultPalette();





const defaultColors = {
  primary: "#fedc5a",
  secondary: "#5454d4",
  offwhite: '#EEEEEE',
  white: "#ffffff",
  dark: "#19191b",
  black: "#000000",
  yellow: "#fedc5a",
  ash: "#413e65",
  green: "#77bf41",
  info: "#0e567c",
  pinegreen: "#7df700",
};

const luxColors = {
  blue: '#12ffee',
  purple: '#9509ff',
  pink: '#ff00e9',
  yellow: '#ffe500',
  green: '#00FF00',
  dark: '#111111'
}

const memeColors = {
  pinkDark: '#EC65D8',
  pink:"#F098D3",
  orangeDark: "#E8722D",
  orange: "#EF8D37",
  greenDark: "#52B53B",
  redDark:"#CA2B5E",
  red: "#EA4683",
  green: "#9DF34B",
  blueDark: "#3F70CD",
  blue: "#6BC7FA",
  yellowDark: "#F2AA3C",
  yellowDarkText: "#a96600",
  yellow: "#F8E04C",
  purpleDark: "#4b10b5" ,
  purple: "#c101f1"
}

const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.75),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.75),
  pinegreen: defaultColors.pinegreen,
  ash: defaultColors.ash,
  bg: defaultColors.white,
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.75),
  warning: defaultColors.yellow,
  success: defaultColors.green,
  info: defaultColors.info,
  primaryBackground:defaultColors.secondary,
  secondaryBackground: defaultColors.offwhite,

  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
    },
  },
};

const theme = {
  initialColorModeName: "light",
  colors: colors,
  cultPalette,
  memeColors,
  luxColors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
};

export default theme;
