import { createGlobalStyle } from "styled-components";
import theme from './theme';

// console.log( theme.cultPalette );
const globalStyle = createGlobalStyle`
  body {
    background-color: #000000;
    color: #ffffff !important;
  }

  p, .p {
    margin-bottom: 0;
  }

  ul,.ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }


  a {
    transition: all 0.3s ease-out;
    &:hover, &:active, &:focus{
      text-decoration: none !important;
      outline: none !important;
    }

    color: ${theme.cultPalette[1] === '#950AFF' ? '#13FFED':theme.cultPalette[2]};
    text-shadow: 1px 1px 0px #000;
    cursor: pointer;

    &:hover {
      color: ${theme.cultPalette[0]};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }



// ~~~~~~~~~~ LUX STYLES ~~~~~~~~~~

  // CULT PANEL + TEXTBOXES
    .cult-panel,
    .cult-panel-colors {
      background: ${theme.cultPalette[1]};
      color: ${theme.cultPalette[1] === '#950AFF' ? '#FFF':'#000'};
      text-shadow: ${theme.cultPalette[1] === '#950AFF' ? '2px 2px 0px #000':'none'};
    }
    .cult-panel {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 5px 20px;
      text-transform: uppercase;
      box-shadow: 5px 6px 0px rgb(0 0 0 / 75%);
      font-weight: bold;
      font-size: 20px;
      font-family: 'pixel9';
      text-align: center;
      border: 3px solid #333;
      transition: all 0.2s ease-in-out;
    }


  // CULT BUTTON 
    .site-wrapper .cult-button,
    .cult-button-colors {
      background: ${theme.cultPalette[0]}; 
      color: ${theme.cultPalette[0] === '#950AFF' ? '#FFFFFF':'#000'};
      text-shadow: ${theme.cultPalette[0] === '#950AFF' ? '2px 2px 0px #000':'none'};
    }

    .site-wrapper .cult-button-alt-colors {
      background: ${theme.cultPalette[2]}; 
      color: ${theme.cultPalette[2] === '#950AFF' ? '#FFFFFF':'#000'};
      text-shadow: ${theme.cultPalette[2] === '#950AFF' ? '2px 2px 0px #000':'none'};
    }

    .cult-button {
      padding: 5px 20px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 28px;
      font-family: 'pixel9';
      border: 3px solid #333;
      box-shadow: 5px 6px 0px rgb(0 0 0 / 75%);
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      // animation: shiftBg 21s ease-in-out infinite;

      &:hover {
        background: #FFF !important;
        color: #000 !important;
        text-shadow: 2px 2px 0px rgba(0,0,0,0) !important;
      }

      @keyframes shiftBg {
          0%  { background: ${theme.cultPalette[0]}; }
          15% { background: ${theme.cultPalette[0]}; }
          33% { background: ${theme.cultPalette[1]}; } 
          45% { background: ${theme.cultPalette[1]}; } 
          66% { background: ${theme.cultPalette[2]}; } 
          82% { background: ${theme.cultPalette[2]}; } 
          100%{ background: ${theme.cultPalette[0]}; }
      }
    }

    .cult-button-subheader {
      pointer-events: none;
      width: auto;
      font-size: 22px;
      display: inline;
    }

    .cult-input-error {
      background: ${theme.cultPalette[2]}; 
      color: ${theme.cultPalette[2] === '#950AFF' ? '#FFFFFF':'#000'};
      text-shadow: ${theme.cultPalette[2] === '#950AFF' ? '2px 2px 0px #000':'none'};
    }

    .cult-active-border:focus {
      outline-color: ${theme.cultPalette[0]};
    }

    .site-wrapper a,
    .cult-link { 
      color: ${theme.cultPalette[1] === '#950AFF' ? '#13FFED':theme.cultPalette[2]};
      text-shadow: 1px 1px 0px #000;
      cursor: pointer;

      &:hover {
        color: ${theme.cultPalette[0]};
      }
    }

    .cult-eye-ball { 
      background-color: ${theme.cultPalette[0]};
    }

    .cult-eye-pupil {
      background-color: ${theme.cultPalette[2]};
    }
`;

export default globalStyle;
