import React from "react";
import Web3Layout from "./src/components/Layout/Web3Layout"
// global.atob = require("atob");

export const wrapPageElement = ({ element, props }) => {
  // Is is server-side rendered?
  const isSSR = typeof window === 'undefined' //|| typeof atob === 'undefined';

  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  if (isSSR) return (<div></div>)
  // return null
  return <Web3Layout {...props}>{element}</Web3Layout>;
};

export const wrapRootElement = ({ element }) => (
  <div className="root-wrapper">{element}</div>
);
