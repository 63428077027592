import React from "react";
import { LuxWeb3Provider } from '../../context/LuxWeb3Context'
import GlobalStyle from "../../utils/globalStyle";

import "./bootstrap-custom.scss";
import "../../assets/fonts/lux-fonts/lux-fonts.css";

const Web3Layout = ({ children }) => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && 
          <React.Suspense fallback={<div />}>

            <GlobalStyle />

            <div className="site-wrapper overflow-hidden">
              <LuxWeb3Provider>
                {children}
              </LuxWeb3Provider>
            </div>

        </React.Suspense>
      }

    </>
  );
};

export default Web3Layout;
